.bridged {
   @extend .content;

   blockquote > p {
      @extend .is-large;
   }

   p > img {
      @extend .box;
      background-color: $scheme-light;
   }

   h1, h2, h3, h4, h5, h6, p, code {
      margin-bottom: $bridge-element-margin;
   }

   img {
      margin-left: auto;
      margin-right: auto;
      display: block;
   }

   h1, h2, h3, h4, h5, h6 {
      @extend .title;
   }

   h1 {@extend .is-1;}

   h2 {@extend .is-2;}

   h3 {@extend .is-3;}

   h4 {@extend .is-4;}

   h5 {@extend .is-5;}

   h6 {@extend .is-6;}

   ol {
      ul {
         margin-top: 0.25em !important;
      }
   }

   pre {
      background-color: inherit !important;
   }

   pre.highlight {
      @extend .box;
      background-color: $code-background-color !important;
      margin: $code-margin;
   }

   code {
      background-color: $code-background-color !important;
   }

   table {@extend .table, .is-striped;}

   th {@extend strong;}

   *:last-child {
      margin-bottom: 0
   }

}

.bridged.box {
   @extend .box;
   background-color: $card-background-color !important;
}