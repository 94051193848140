@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://cdn.jsdelivr.net/npm/katex@0.12.0/dist/katex.min.css);

$family-sans-serif: Ubuntu, Roboto, sans-serif;
$bridge-element-margin: 1.5rem;
$code-margin: 1rem;

$border:                                  hsl(24, 9%, 11%);
$scheme-main:                             hsl(24, 9%, 16%);
$scheme-light:                            hsl(24, 9%, 35%);
$background:                              hsl(24, 9%, 13%);
$card-background-color:                   $background;
$code-background-color:                   hsl(24, 9%, 9%);
$text:                                    hsl(21, 6%, 80%);
$text-light:                              hsl(21, 6%, 40%);
$text-strong:                             hsl(21, 6%, 90%);
$table-background-color:                  hsl(24, 9%, 12%);
$table-striped-row-even-background-color: hsl(24, 9%, 15%);

$hr-background-color:                     $code-background-color;
$subtitle-color:                          $text-light;
$box-background-color:                    $background;

$link:       hsl(217, 59%, 80%);
$link-active: $link;
$link-hover: $text-strong;

$navbar-item-hover-background-color: hsl(24, 9%, 14%);
$navbar-height: 4rem;
$radius-large: 9px;

$container-max-width: 100px;

@import "bulma-0.9.0/bulma";
@import 'bulma-collapsible/index.sass';
@import "_syntax-highlighting";
@import "bridge";
@import "garden";



// Background texture settings
body {
   background: url(shattered.png);
   background-attachment: fixed;
   height: 100%; }

html {
   min-height: 100%; }

.full {
   min-height: 100vh; }

.navbar-logo {
   height: 50px;
   width: 33.202px; }

.navbar-burger {
   @extend .box;
   background-color: $card-background-color;
   height: 3.5rem;
   width: 3.5rem;
   padding: 0px;
   margin: auto 5px auto auto;
   box-shadow: none; }

a:hover {
   color: hsl(21, 6%, 95%) !important; }

.svg-icon {
   margin: auto; }

.no-select {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-tap-highlight-color: transparent; }

.content-card {
   margin-bottom: 1.5rem; }

.post-box {
   background-color: $code-background-color;
   box-shadow: 0 0.5em 1em -0.125em #000000;

   .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; } }

.post-is-3 {
   @extend .is-one-third; }

.post-is-2 {
   @extend .is-two-thirds; }

.post-is-1 {
   @extend .is-full; }

.nooverflow {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap; }

.tags {
   display: inline;
   text-decoration: underline; }

.tags:hover {
   color: $text-strong; }

.tag-icon {
   margin-bottom: 0.2rem !important; }

.post-link {
   cursor: pointer; }

.tag-box {
   background-color: $code-background-color;
   margin: 0px 10px 15px 10px !important;
   box-shadow: 0 0.5em 1em -0.125em #000000;
   padding: 15px;
   text-align: center; }

.numberBubble {
   border-radius: 1em;
   min-width: 1em;
   height: 1em;
   width: fit-content;
   background-color: $text;
   color: $code-background-color;
   margin: auto;
   text-align: center;
   vertical-align: top;
   padding: 3px;

   justify-content: center;
   align-items: center;
   display: flex; }

.page {
   max-width: 960px;
   margin: auto; }

.is-collapsible {
   padding: 0 18px;
   max-height: 0;
   overflow: hidden;
   transition: max-height 0.2s ease-out; }

.collapsible {
   @extend .button;
   margin-bottom: 15px; }

.spoiler-box {
   @extend .box;
   background-color: $scheme-main; }

@media screen and (max-width: $desktop) {
   .navbar-menu {
      background-color: $table-background-color; }

   .post {
      max-width: 100%; } }

@media screen and (min-width: $desktop) {
   .post-box {
      overflow: hidden;
      max-width: 100%; }

   .posts-column {
      margin: 0; }

   .post {
      width: 25rem; } }

@mixin max-lines-with-fadeout($num-lines, $line-height, $fade-lines) {
   $num-lines: ($num-lines / $fade-lines);
   $line-height: $line-height * $fade-lines;
   // Height of the container should be 1 more than the specified
   // number of lines ($num-lines).
   //
   // The extra line will be faded out.
   height: $line-height * ($num-lines + 1) * 1em;
   // Using a negative margin equal to one line height fixes
   // the container height back to $num-lines
   margin-bottom: -$line-height * 1em;
   // Don't show any text outside the container
   overflow: hidden;
   // Define gradient start and end points based on
   // $num-lines such that the fading begins only on
   // the "extra" line below $num-lines of text
   $gradient-start: ($num-lines - 0.9) / $num-lines * 100%;
   $gradient-end: ($num-lines - 0.2) / $num-lines * 100%;
   // Use mask-image to apply the fade-out effect on the
   // $num-lines + 1 line of text. If the content is shorter
   // than $num-lines, the dummy blank line will be faded out
   // instead (see the &::after style below)
   mask-image: linear-gradient(to bottom, black $gradient-start, transparent $gradient-end);
   -webkit-mask-image: linear-gradient(to bottom, black $gradient-start, transparent $gradient-end);
   // Always add a blank line at the end of the content
   // to ensure short content (within $num-lines limit)
   // will render without clipping at the bottom.
   &::after {
      content: " ";
      display: block;
      height: $line-height * 1em; } }

.excerpt {
   @include max-lines-with-fadeout(3, $body-line-height, 1); }

.toots {
   margin: 12px 0px; }

time {
   @extend .subtitle;
   @extend .tags;
   font-size: 1rem; }

.toot {
   @extend .post-box;
   @extend .box;
   margin: 12px 0px; }

.avatar {
   display: none; }

.user {
   display: none; }

img.attachment {
   margin: 10px auto;
   max-width: 100%;
   max-height: 190px;
   display: block; }
