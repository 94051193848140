.garden-box {
    @extend .box;
    box-shadow: 0 0.5em 1em -0.125em #000000
}

#css-zen-garden {

    .page-wrapper {
        padding: 20px;
        max-width: 1200px;
        margin: auto;

        div[role=article] {
            @extend .section;
        }

        div[role=main] {
            @extend .garden-box;
            background-color: $card-background-color !important;
        }

        abbr {
            text-decoration: none;
        }

        #zen-intro {
            @extend .garden-box;

            header {

                text-align: center;

                h1 {@extend .title;}
                h2 {@extend .subtitle;}
            }

            .summary {
                @extend .columns;

                p {
                    @extend .title;
                    @extend .is-4;
                    margin: auto !important;
                }

                p:last-child {
                    @extend .is-one-third;
                    @extend .is-6;
                    @extend .garden-box;
                    background-color: $code-background-color;
                    margin: 15px !important;
                    text-align: center;
                }
            }

            .preamble {
                @extend .columns;
                @extend .garden-box;
                @extend .is-multiline;
                background: url(csis304/garden/cartographer.png);
                background-attachment: fixed;
                margin: 10px !important;
                background-color: black;

                h3 {
                    @extend .column;
                    @extend .is-full;
                    @extend .title;
                    text-align: center;
                }

                p {
                    @extend .column;
                    @extend .is-one-third;
                    margin: auto;
                }
            }
        }

        #zen-supporting {
            background: url(csis304/garden/flowers.png);
            background-color: black;
            background-attachment: fixed;

            div[role=article] {
                @extend .garden-box;
                @extend .columns;
                @extend .is-multiline;
                margin: 50px 15px !important;
                background-color: $code-background-color;

                h3 {
                    @extend .column;
                    @extend .is-full;
                    @extend .title;
                    text-align: center;
                }

                p {
                    @extend .column;
                    @extend .is-half;
                    margin: auto;
                }

                #zen-requirements {
                    p {
                        @extend .is-half;
                    }
                }
            }

            footer {
                @extend .subtitle;
                text-align: center;
            }

            .explanation, .participation, .benefits, .requirements {
                background: url(csis304/garden/low-contrast-linen.png);
            }
        }

        .sidebar {
            .wrapper {
                @extend .garden-box;
                @extend .columns;
                @extend .is-multiline;
                margin: 10px !important;

                .design-selection {
                    @extend .garden-box;
                    @extend .menu;
                    @extend .column;
                    @extend .is-half;
                    margin: 15px !important;
                    background-color: $code-background-color !important;

                    h3 {
                        text-align: center;
                        @extend .title;
                        @extend .is-4;
                        @extend .menu-label;
                    }

                    ul {
                        @extend .menu-list;
                        text-align: center;
                    }

                    li {
                        a {
                            display: inline-block;
                        }
                    }
                }

                .design-archives {
                    @extend .garden-box;
                    @extend .column;
                    margin: 15px !important;
                    background-color: $code-background-color !important;

                    h3 {
                        text-align: center;
                        @extend .title;
                        @extend .is-4;
                        @extend .menu-label;
                    }

                    ul {
                        @extend .menu-list;
                        text-align: center;
                    }

                    li {
                        a {
                            display: inline-block;
                        }
                    }
                }

                .zen-resources {
                    @extend .garden-box;
                    @extend .column;
                    margin: 15px !important;
                    background-color: $code-background-color !important;

                    h3 {
                        text-align: center;
                        @extend .title;
                        @extend .is-4;
                        @extend .menu-label;
                    }

                    ul {
                        @extend .menu-list;
                        text-align: center;
                    }

                    li {
                        a {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}